"use client"

import { Suspense, useCallback, useEffect } from "react"
import { useSearchParams } from "next/navigation"
import { env } from "~/env"
import Cookies from "js-cookie"
import { sha256 } from "js-sha256"
import type { InsightsMethodMap } from "search-insights"

import { useAnalyticsContext, useAnalyticsEvent, useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY } from "~/lib/algolia/constants"
import { algoliaSearchInsights } from "~/lib/algolia/public-client"
import { getIndexName } from "~/lib/algolia/utils/get-index-name"
import { COOKIE_KEYS } from "~/lib/constants"
import type { I18nLocale } from "~/lib/i18n/types"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import { useAlgoliaSearch } from "~/hooks/useAlgoliaSearch"
import useLocale from "~/hooks/useLocale"
import { useCookiesConsentDone } from "~/managers/CookiesConsentManager"
import { objectEntries } from "~/utils/object-entries"

type FiltersEventPayload = Parameters<Parameters<typeof useAnalyticsEvent<"clickedFilters">>[0]["callback"]>[0]

function convertToFitersAsArray(event: FiltersEventPayload) {
  return (
    objectEntries(event?.filters ?? {})
      ?.flatMap(([key, value]) => {
        return value?.map((filter) => `${key}:${filter}`)
      })
      // we can only include up to 10 filters
      // https://www.algolia.com/doc/api-reference/api-methods/clicked-filters/#method-param-filters
      ?.slice(0, 10) ?? []
  )
}

export function useSendAlgoliaAnalyticsEvent() {
  const locale = useLocale()

  function partialInitSearchInsights() {
    algoliaSearchInsights("init", {
      appId: env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
      partial: true,
    })
  }

  const index = getIndexName({ locale: locale as I18nLocale })

  const sendAlgoliaSearchInsightsEvent = useCallback(
    <TKey extends keyof InsightsMethodMap>({
      key,
      isSearchEvent = false,
      ...input
    }: { key: TKey; isSearchEvent?: boolean } & InsightsMethodMap[TKey][0][0]) => {
      partialInitSearchInsights()
      let { queryID } = input

      // If it's a search event and we don't have a queryID, we try to get it from the local storage
      // Because it cannot persist in the PDP as we're out of the Algolia context
      // And setting the fallback from the localStorage causes a redundancy
      if (isSearchEvent && !queryID) {
        queryID = localStorage.getItem(ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY)
      }

      algoliaSearchInsights(key, { index, ...input, ...(isSearchEvent && queryID ? { queryID } : {}) })
    },
    [index]
  )

  return { sendAlgoliaSearchInsightsEvent }
}

export const ALGOLIA_TRACK_QUERY_PARAM = "afterSearch"

function AlgoliaAnalyticsEvents() {
  let hasUserConsent = useCookiesConsentDone()

  const locale = useLocale()
  const { data: customer } = useGetCustomer()

  const index = getIndexName({ locale: locale as I18nLocale })

  const canProcessEvent = hasUserConsent && Boolean(index)

  const { data } = useAlgoliaSearch() ?? {}
  const searchParams = useSearchParams()

  const shouldTrackAsAlgoliaConversion = Boolean(searchParams?.get(ALGOLIA_TRACK_QUERY_PARAM) === "1")

  // Finding the last queryID from the last page we fetched
  const { queryID } = data ?? {}

  const results = data?.hits ?? []

  const { sendAlgoliaSearchInsightsEvent } = useSendAlgoliaAnalyticsEvent()

  const { template } = useAnalyticsContext()
  const isTemplateCollectionOrSearch = template === "collection" || template === "search"
  const isFromProductPageAndShouldTrack = template === "product" && shouldTrackAsAlgoliaConversion

  const shouldSendSearchEvent = isTemplateCollectionOrSearch || isFromProductPageAndShouldTrack

  // Getting the unique user ID from the Shopify cookies in order to get the same on the checkout side
  // Thanks @raroul for the tip :)
  const shopifyUserID = Cookies.get(COOKIE_KEYS.SHOPIFY_USER_ID)

  useEffect(() => {
    if (shopifyUserID) {
      algoliaSearchInsights("setUserToken", shopifyUserID ?? "")
    }

    if (customer?.email) {
      algoliaSearchInsights("setAuthenticatedUserToken", sha256(customer?.email) ?? "")
    }
  }, [shopifyUserID, customer])

  useEffect(() => {
    algoliaSearchInsights("init", {
      appId: env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
      // https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/#exclude-users-who-want-to-opt-out-of-analytics-recommend-and-personalization
      useCookie: Boolean(hasUserConsent),
      partial: true,
    })
  }, [hasUserConsent])

  useEffect(() => {
    if (queryID) {
      localStorage.setItem(ALGOLIA_QUERY_ID_LOCAL_STORAGE_KEY, queryID)
    }
  }, [queryID])

  useAnalyticsEvent({
    type: "viewItemList",
    canProcessEvent,
    callback: (event) => {
      const objectIDs =
        event?.products?.map((product) => getReferenceIdFromGID(product?.id)?.toString())?.filter(Boolean) ?? []

      sendAlgoliaSearchInsightsEvent<"viewedObjectIDs">({
        key: "viewedObjectIDs",
        eventName: "View product cards",
        objectIDs,
      })
    },
  })

  useAnalyticsEvent({
    type: "selectItem",
    canProcessEvent,
    callback: (event) => {
      const { products } = event ?? {}
      const [product] = products ?? []

      if (!product) return

      const objectID = getReferenceIdFromGID(product?.id)?.toString()

      if (!objectID) return

      const commonProps = {
        eventName: "Click on product card",
        objectIDs: [objectID],
      }

      if (!shouldSendSearchEvent) {
        sendAlgoliaSearchInsightsEvent<"clickedObjectIDs">({
          key: "clickedObjectIDs",
          ...commonProps,
        })

        return
      }

      const position = results?.findIndex((product) => product?.objectID === objectID)

      if (position === -1) return

      sendAlgoliaSearchInsightsEvent<"clickedObjectIDsAfterSearch">({
        key: "clickedObjectIDsAfterSearch",
        positions: [position + 1],
        queryID: queryID,
        isSearchEvent: true,
        ...commonProps,
      })
    },
  })

  useAnalyticsEvent({
    type: "clickedFilters",
    canProcessEvent,
    callback: (event) => {
      const filtersAsArray = convertToFitersAsArray(event)

      if (!filtersAsArray?.length) return

      sendAlgoliaSearchInsightsEvent<"clickedFilters">({
        key: "clickedFilters",
        eventName: "Submitted filters",
        filters: filtersAsArray,
      })
    },
  })

  useAnalyticsEvent({
    type: "viewFilters",
    canProcessEvent,
    callback: (event) => {
      const filtersAsArray = convertToFitersAsArray(event)

      sendAlgoliaSearchInsightsEvent<"viewedFilters">({
        key: "viewedFilters",
        eventName: "Viewed filters panel",
        filters: filtersAsArray,
      })
    },
  })

  useAnalyticsEvent({
    type: "addToCart",
    canProcessEvent,
    callback: (event) => {
      const { currency, products } = event ?? {}
      const [product] = products ?? []

      if (!product) return

      const objectID = getReferenceIdFromGID(product?.id)?.toString()

      if (!objectID) return

      const { price, quantity, discount } = product

      const commonProps = {
        eventName: "Add to cart",
        objectIDs: [objectID],
        objectData: [
          {
            discount,
            price,
            quantity,
          },
        ],
        value: price,
        currency: currency?.toUpperCase(),
      }

      if (!shouldSendSearchEvent) {
        sendAlgoliaSearchInsightsEvent<"addedToCartObjectIDs">({
          key: "addedToCartObjectIDs",
          ...commonProps,
        })
      } else {
        sendAlgoliaSearchInsightsEvent<"addedToCartObjectIDsAfterSearch">({
          key: "addedToCartObjectIDsAfterSearch",
          queryID: queryID,
          isSearchEvent: true,
          ...commonProps,
        })
      }
    },
  })

  return null
}

function SuspensedAlgoliaEvents() {
  return (
    <Suspense fallback={null}>
      <AlgoliaAnalyticsEvents />
    </Suspense>
  )
}

export { SuspensedAlgoliaEvents as AlgoliaAnalyticsEvents }
