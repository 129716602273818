import { useQuery } from "@tanstack/react-query"

import "~/lib/algolia/constants"

import { captureException } from "@sentry/nextjs"

import { getAlgoliaPublicIndex } from "~/lib/algolia/public-client"
import type { AlgoliaProductHit, AlgoliaPromoCardHit } from "~/lib/algolia/types"
import type { I18nLocale } from "~/lib/i18n/types"
import { COLLECTION_HITS_PER_PAGE } from "~/lib/shopify/constants"
import useLocale from "~/hooks/useLocale"
import { useAlgoliaCustomContext, type AlgoliaCustomContextState } from "~/providers/AlgoliaCustomContext/index.client"
import { objectEntries } from "~/utils/object-entries"

type AlgoliaSearchQueryKeys = Pick<
  AlgoliaCustomContextState,
  "query" | "sortBy" | "selectedFilters" | "page" | "initialSearchProps"
> & {
  locale: I18nLocale
  hitsPerPage: number
}

export function useAlgoliaSearch() {
  const nextLocale = useLocale() as I18nLocale
  const { isFiltering, isSorting, initialSearchProps, initialData, page, query, selectedFilters, sortBy } =
    useAlgoliaCustomContext()

  const enabled = Boolean(isFiltering || isSorting || query)

  return useQuery<
    Partial<SearchResponse<AlgoliaProductHit | AlgoliaPromoCardHit>>,
    Error,
    SearchResponse<AlgoliaProductHit | AlgoliaPromoCardHit>,
    [string, AlgoliaSearchQueryKeys]
  >({
    enabled,
    initialData,
    refetchOnMount: true,
    queryKey: [
      "algolia-search",
      {
        page,
        query,
        selectedFilters,
        sortBy,
        initialSearchProps,
        locale: nextLocale,
        hitsPerPage: initialSearchProps?.hitsPerPage || COLLECTION_HITS_PER_PAGE,
      },
    ],
    queryFn: async ({ queryKey: [_, { page, query: queryString, selectedFilters, hitsPerPage, sortBy, locale }] }) => {
      const algoliaPublicClient = getAlgoliaPublicIndex({ locale, ...(sortBy && { suffix: sortBy }) })

      const facetFilters =
        objectEntries(selectedFilters)
          ?.filter(([_, values]) => (values ?? [])?.filter(Boolean)?.length)
          ?.map(([key, values]) => {
            return values?.map((value) => `${key}:${value}`)
          }) ?? []

      return algoliaPublicClient
        .search<AlgoliaProductHit | AlgoliaPromoCardHit>(queryString ?? "", {
          ...initialSearchProps,
          facetFilters,
          hitsPerPage,
          page: (page || 1) - 1,
        })
        ?.catch((error) => {
          captureException(error)
          return { hits: [] }
        })
    },
  })
}
